import { GetServerSideProps } from 'next';
import * as React from 'react';
import { ProductPagination } from '~source/core/models/components/molecules/product-teaser';
import { Homepage } from '~source/core/models/components/templates/page';
import * as api from '~source/core/services/api/get';
import {
  productPaginationEmpty,
  searchProductsByCollection,
} from '~source/core/services/eva/api/search-products';
import transformHomepage from '~source/core/transformers/api/transform-homepage';
import transformMeta from '~source/core/transformers/api/transform-meta';
import catchError from '~source/core/utils/catch-error';
import { JSONLike } from '~source/core/utils/helper-types';
import MetaTag, {
  MetaTagProps,
} from '~source/ui/components/atoms/meta-tag/meta-tag';
import ShirtIcon from '~source/ui/components/atoms/shirt-icon/shirt-icon';
import ModuleHandler, {
  ProductOptions,
} from '~source/ui/components/templates/module-handler/module-handler';
import useLocale from '~source/ui/hooks/helper/useLocale/useLocale';
import useFilteredProducts from '~source/ui/hooks/product/useFilteredProducts/useFilteredProducts';
import { useNavigation } from '~source/ui/hooks/ui/useNavigation';
import assertLocale from '~source/ui/utils/checks/assertLocale';
import setDYContext from '~source/ui/utils/dynamic-yield/set-dy-context';

type Props = {
  data: Homepage | null;
  meta: MetaTagProps;
  productPagination: ProductPagination;
};

function Home({ data, productPagination, meta }: Props) {
  const { products, totalProducts, currentPage, totalPages } =
    productPagination;
  const {
    filteredProducts,
    filters,
    setFilter,
    sortDirection,
    setSortDirection,
  } = useFilteredProducts({
    productCollection: data?.productCollection ?? null,
    products,
  });
  const locale = useLocale();
  const { setIsTakeoverBanner } = useNavigation();

  React.useEffect(() => {
    setDYContext('HOMEPAGE', locale);
  }, [locale]);

  React.useEffect(() => {
    if (data?.isTakeoverBanner) setIsTakeoverBanner(data.isTakeoverBanner);
  }, [data?.isTakeoverBanner]);

  const productOptions: ProductOptions = React.useMemo(
    () => ({
      products: filteredProducts,
      selectedProductFilterOptions: filters,
      setProductFilterOption: setFilter,
      selectedSortDirection: sortDirection,
      setSortDirection,
      totalProducts,
      currentPage,
      totalPages,
    }),
    [
      filteredProducts,
      filters,
      setFilter,
      sortDirection,
      setSortDirection,
      totalProducts,
      currentPage,
      totalPages,
    ],
  );

  return (
    <>
      <MetaTag {...meta} />
      <ShirtIcon />
      {data ? (
        <>
          <ModuleHandler
            modules={data.modules}
            productOptions={productOptions}
          />
          <style dangerouslySetInnerHTML={{ __html: data.navigationStyles }} />
        </>
      ) : (
        <h1>No data available</h1>
      )}
    </>
  );
}

export const getServerSideProps: GetServerSideProps<Props> = async ({
  locale,
}) => {
  assertLocale(locale);
  const response = await api.get({
    path: '/home',
    culture: locale,
  });

  const pageData = await transformHomepage(response);
  if (!pageData?.slug) return { notFound: true };
  const productPage = await catchError(async () => {
    if (!pageData.productCollection) return productPaginationEmpty;

    return searchProductsByCollection({
      collection: pageData.productCollection,
      locale,
    }).promise;
  }, productPaginationEmpty);

  return {
    props: {
      data: pageData,
      meta: response
        ? transformMeta(response.fields as Record<string, JSONLike>)
        : {},
      productPagination: productPage,
    },
  };
};

export default Home;
