import * as React from 'react';
import Container from '~source/ui/components/containers/container/container';
import $ from './shirt-icon.module.scss';

export default function ShirtIcon() {
  return (
    <div className={$.fixedSymbol}>
      <Container>
        <span className={$.symbolShirt} />
      </Container>
    </div>
  );
}
